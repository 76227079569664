import type { PolygonGeometry } from '@/components/soon-shared/util';

/*
 * The properties casing should be CamelCase for new properties and snake_case for the
 * properties coming from the API or derived from a property from the API
 * (e.g. ExteriorWall_window_share_N derived from ExteriorWall_window_share.N)
 */
export interface ThermalZoneProperties {
  name: string;
  building_id: string;
  building_name: string;

  altitude: number;
  height: number;
  floor_count: number;
  mean_floor_height: number;

  gross_floor_area: number;
  usable_floor_area_ratio: number;
  usable_floor_area: number;

  usage_zone_code: UsageCode;
  performance: Performance;
  year: number | null;

  infiltration_rate: number;
  ventilation_system: VentilationSystemType;

  ExteriorRoof_U_value: number;
  ExteriorRoof_inertia: Inertia;
  ExteriorRoof_insulation: Insulation;
  ExteriorRoof_window_type: string;
  ExteriorRoof_window_U_value: number;
  ExteriorRoof_window_solar_absorption: number;
  ExteriorRoof_window_transmission_factor: number;
  ExteriorRoof_window_share: number;

  ExteriorWall_U_value: number;
  ExteriorWall_inertia: Inertia;
  ExteriorWall_insulation: Insulation;
  ExteriorWall_window_type: string;
  ExteriorWall_window_U_value: number;
  ExteriorWall_window_solar_absorption: number;
  ExteriorWall_window_transmission_factor: number;

  ExteriorWall_window_share_N: number;
  ExteriorWall_window_share_NE: number;
  ExteriorWall_window_share_NW: number;
  ExteriorWall_window_share_S: number;
  ExteriorWall_window_share_SE: number;
  ExteriorWall_window_share_SW: number;
  ExteriorWall_window_share_E: number;
  ExteriorWall_window_share_W: number;

  ExteriorFloor_U_value: number;
  ExteriorFloor_inertia: Inertia;
  ExteriorFloor_insulation: Insulation;

  blind_control_mode: BlindControlModeType;

  /*
   * Usage profiles
   * --------------
   *
   * By default no usage profile is set: the user 'opts in' to compute the energy demand for the
   * requested services.
   *
   * The user chooses which computation to perform using a profile mode: scenario or load curve.
   * Depending on the mode, either a scenarioId or a loadCurveId is expected. Each of the three
   * modes constrain one or more services.
   *
   * Additionally, it is not possible to have heating and cooling in scenario mode and domestic
   * hot water or specific electricity in load curve mode. (WHY????) This is enforced by the UI
   * and verified by the facade before the API call.
   *
   * Only the relevant IDs are sent to the backend (scenario or load curve) but both are kept in
   * the interface to allow not losing the previous setting when the mode is changed. This is not
   * saved in the backend and therefore lost on refresh.
   */

  heatingAndCoolingProfileMode: ProfileModes; // constrains heating, cooling, airChangeRate and internalGainMetabolism
  heatingScenarioId?: number;
  heatingLoadCurveId?: number;
  coolingScenarioId?: number;
  coolingLoadCurveId?: number;
  airChangeRateScenarioId?: number;
  internalGainMetabolismScenarioId?: number;

  domesticHotWaterProfileMode: ProfileModes;
  domesticHotWaterScenarioId?: number;
  domesticHotWaterLoadCurveId?: number;

  specificElectricityProfileMode: ProfileModes;
  specificElectricityScenarioId?: number;
  specificElectricityLoadCurveId?: number;

  flying_exterior_floor: boolean;
}

export interface ThermalZone {
  id: string; // study-local id like 'z_1'
  properties: ThermalZoneProperties;
  geometry: PolygonGeometry;
}

export interface ThermalZoneApi {
  getStudyThermalZones(studyId: number): Promise<ThermalZone[]>;
  persistStudyThermalZones(studyId: number, thermalZones: ThermalZone[]): Promise<void>;
}

export const usageCodeOptions = [
  'B00',
  'B01',
  'B02',
  'B03',
  'B04',
  'B05',
  'B06',
  'B07',
  'B08',
  'B09',
  'B10',
  'B11',
  'B12',
  'B13',
  'B14',
  'B15',
  'B16',
  'B18',
  'B19',
  'B20',
  'B21',
  'B22',
  'B23',
  'B25',
  'B26',
  'B27',
  'B28',
  'B29',
  'B30',
  'B31',
  'B33',
  'B34',
  'B35',
  'B36',
  'B37',
  'B39',
  'B40',
  'B43',
  'B44',
  'B46',
  'B47',
] as const;
export type UsageCode = (typeof usageCodeOptions)[number];

export const performanceOptions = [
  'UNDEFINED',
  '0-1938',
  '1939-1968',
  '1969-1975',
  '1976-1983',
  '1984-1996',
  '1997-2008',
  '2009-2014',
  '2015-2018',
  'E1',
  'E2',
  'E3',
  'E4',
] as const;
export type Performance = (typeof performanceOptions)[number];

export const inertiaOptions = ['low', 'mid', 'high'] as const;
export type Inertia = (typeof inertiaOptions)[number];

export const insulationOptions = ['indoor', 'outdoor'] as const;
export type Insulation = (typeof insulationOptions)[number];

export const flyingExteriorFloorOptions = ['ground', 'air'] as const;
export type FlyingExteriorFloor = 'false' | 'true';

export const windowTypeOptions = [
  'single_glazing',
  'double_glazing',
  'triple_glazing',
  'custom_glazing',
] as const;
export type WindowType = (typeof windowTypeOptions)[number];

export type StandardWindowType = Exclude<WindowType, 'custom_glazing'>;

export type GlazingType = {
  window_U_value: number;
  window_transmission_factor: number;
  window_solar_absorption: number;
};

export const windowTypeValues: Record<string, GlazingType> = {
  single_glazing: {
    window_U_value: 5.0,
    window_transmission_factor: 0.789,
    window_solar_absorption: 0.08,
  },
  double_glazing: {
    window_U_value: 1.8,
    window_transmission_factor: 0.7,
    window_solar_absorption: 0.15,
  },
  triple_glazing: {
    window_U_value: 0.8,
    window_transmission_factor: 0.6,
    window_solar_absorption: 0.3,
  },
} as const;

export const ventilationSystemTypeOptions = [
  'ZoneSingleFlowVentilation',
  'ZoneHeatRecoveryVentilation',
] as const;
export type VentilationSystemType = (typeof ventilationSystemTypeOptions)[number];

export const blindControlModeOptions = [0, 1, 2] as const;
export type BlindControlModeType = (typeof blindControlModeOptions)[number];

export type ProfileModes = 'SCENARIO' | 'LOAD_CURVE';
